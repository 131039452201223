import { Injectable } from '@angular/core';
import { Configuration } from './../types/configuration';
import configJson from './../../configurations/configuration-dev/configuration.json';
import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  fetched = false;
  fetching = false;
  config: Configuration = configJson;
  path = '/configuration.json';

  constructor() {}

  load(): Promise<void> {
    return new Promise((resolve) => {
      if (this.fetched) {
        resolve();
      } else if (this.fetching) {
        const fetchCheck = window.setInterval(() => {
          if (this.fetched) {
            window.clearInterval(fetchCheck);
            resolve();
          }
        }, 1000);
      } else {
        this.fetch().then(() => resolve());
      }
    });
  }

  fetch(path: string = '/configuration.json'): Promise<void> {
    this.fetching = true;
    this.path = path;
    return fetch(this.path)
      .then((res) => {
        this.fetching = false;
        this.fetched = true;
        if (res.ok) {
          // console.log("We've gotten a response, and it's okay.", res);
          return res.json();
        } else {
          throw new Error(
            `Environment Configuration not retrievable from server. (${res.status}) ${res.statusText}`
          );
        }
      })
      .then((json) => {
        if (json) {
          this.config = json;
          // console.log("We've parsed the json, config is loaded.", this.config);
        } else {
          throw new Error('Json response is empty');
        }
      });
  }

  getAuthConfig(): Promise<OpenIdConfiguration> {
    return new Promise((resolve) => {
      this.fetched
        ? resolve(this.extractAuthConfig())
        : this.load().then(() => resolve(this.extractAuthConfig()));
    });
  }

  private extractAuthConfig() {
    const matches = /DefaultDomain\??(?:=|%3D)([^\],&]*)/.exec(window.location.search);
    const customParamsAuthRequest = matches && matches.length > 1 ? {
      domain: matches[1]
    } : {};
    return <OpenIdConfiguration>{
      configId: 'idsrv-dev',
      authority: this.config.idServerURL,
      authWellknownEndpointUrl:
        this.config.idServerURL + '.well-known/openid-configuration',
      redirectUrl: window.location.origin + '/auth/login',
      postLogoutRedirectUri: window.location.origin + '/auth/logout',
      clientId: 'Istation.OLP',
      responseType: 'code',
      silentRenew: true,
      maxIdTokenIatOffsetAllowedInSeconds: 600,
      useRefreshToken: true,
      logLevel: this.config.production
        ? LogLevel.None
        : this.config.featureFlags.showAuthDebug
        ? LogLevel.Debug
        : LogLevel.None,
        secureRoutes: [ 
          this.config.idServerURL,
          this.config.usageURL,
          this.config.assessmentApiUrl,
          this.config.contentMetadataUrl,
          this.config.amiraAssignmentUrl,
          this.config.amiraSRSUrl,
          this.config.amiraSISUrl,
      ],  
      customParamsAuthRequest,
    };
  }
}
