import { DOCUMENT, Location } from '@angular/common';
import { Router } from '@angular/router';
import { Component, HostListener, OnInit, OnDestroy, inject } from '@angular/core';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import {
	CompatibilityService,
	FeatureFlagService,
  RuntimeDataService,
	UrlService,
	UserService,
	WasmService,
  SignalRService
} from './services';
import { TranslateService } from '@ngx-translate/core';
import { AccessTokenPayload } from './types';
import { EventDispatch } from './classes';
import { AppConfigParams, Grades, LanguageCodes, MessageCodes, RuntimeStatus, Themes } from './enums';
import { TrackingService } from './shared/services/tracking.service';
import { LOCAL_STORAGE, WINDOW } from '@ng-web-apis/common';
import { LOADING, RUNTIME_STATUS } from './core/injection-tokens';
import { ConfigurationService } from './services/configuration.service';
import { LoaderService } from './services/loader.service';

enum TabState {
  ANNOUNCE = 'anyone-here',
  RESPOND = 'someones-here'
}

export type OidcUserInfo = {
  oid: string;
  role: string[];
  given_name: string;
  family_name: string;
  name: string;
  domainId: string;
  grade?: string;
}

export const openDebugConsoleEventName = 'openDebugConsoleEvent';

@Component({
  selector: 'swe-stage',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly tabUniqueName = 'IstationTab';
  
  title = 'stage';
  themeID = 'swe-theme';
  overlayEnabled = false;
  isCompatible = new Subject<boolean>();
	canUseDebugPanel = false;	// no DOM element will be created if false
  ignoreBrowserCheck = false;
  showDebug = false;
  public showLoading: BehaviorSubject<boolean> = inject(LOADING);

  private document: Document = inject(DOCUMENT);
  private window: Window = inject(WINDOW);
  private localStorage: Storage = inject(LOCAL_STORAGE);
  private location: Location = inject(Location);
  private router: Router = inject(Router);
  private dispatch: EventDispatch = inject(EventDispatch);
  private runtimeStatus = inject(RUNTIME_STATUS);

  public wasmService: WasmService = inject(WasmService);
  private translate: TranslateService = inject(TranslateService);
  private userService: UserService = inject(UserService);
  private compatibilityService: CompatibilityService = inject(CompatibilityService);
  private urlService: UrlService = inject(UrlService);
  private oidcSecurityService: OidcSecurityService = inject(OidcSecurityService);
  private signalRService: SignalRService = inject(SignalRService);
  private configurationService: ConfigurationService = inject(ConfigurationService);
  private featureFlags: FeatureFlagService = inject(FeatureFlagService);
  private trackingService: TrackingService = inject(TrackingService);
  private runtimeDataService: RuntimeDataService = inject(RuntimeDataService);
  private loaderService: LoaderService = inject(LoaderService);

  private destroy$ = new Subject();

  constructor() {
    this.router.canceledNavigationResolution = 'computed';
    const searchParams = new URLSearchParams(this.window.location.search);
    const hasKeys = searchParams.toString().length > 0;
    if (hasKeys) {
      this.urlService.saveQueryParams(searchParams);
    }
    this.wasmService.allowAutomation = this.urlService.params?.[AppConfigParams.AllowAutomation]?.toLowerCase() === 'true';
    this.wasmService.showDebugPanelBehavior
      .pipe( filter(() => this.canUseDebugPanel))
      .subscribe(show => this.showDebug = show);
    this.wasmService.onlogoutCallback = async () => {
      this.showLoading.next(true);
      if (this.featureFlags.isFlagEnabled('signalRService')) {
        await this.signalRService.unsubscribeServer();
      }      
      this.oidcSecurityService
        .logoffAndRevokeTokens()
        .pipe( takeUntil(this.destroy$) )
        .subscribe(g => g);
      this.localStorage.removeItem('theme');
    }
    this.runtimeStatus
      .pipe(first(s => s >= RuntimeStatus.SUPERVISOR_ACTIVE))
      .subscribe(() => {
        this.runtimeDataService.saveClickstreamSession();
        this.showLoading.next(false);
      });
    this.translate.addLangs(Object.values(LanguageCodes));
    this.translate.setDefaultLang(LanguageCodes.English);
  }
  

  ngOnInit(): void {
    this.runOtherTabChecks();
    this.urlService.loadParams();
    const shouldLoadNativeApp = this.compatibilityService.checkLegacyLauncher();
    // Don't check compatibility if student will be redirected to the native application
    !shouldLoadNativeApp && this.checkCompatibility();
    const auth$: Observable<LoginResponse> = this.oidcSecurityService
      .checkAuth()
      .pipe(
        takeUntil(this.destroy$),
        filter<LoginResponse>(({isAuthenticated, accessToken}) => !!(isAuthenticated && accessToken)),
      );
    auth$
      .subscribe({
        next: ({ userData, accessToken }: {userData: OidcUserInfo, accessToken: string}) => {
          if (shouldLoadNativeApp){
            // Eject to the native app
            this.router.navigate(['app-launch']);
          } else {
            // Load SignalR session watch
            const authUrl: string = this.configurationService.config.idServerURL;
            if (this.featureFlags.isFlagEnabled('signalRService')
              && accessToken) {
              this.signalRService
                .connectToServer(`${authUrl}messageHub`)
                .then((result) => {
                  if (result) {
                    this.signalRService.invokeMethod('VerifyToken', accessToken);
                  }
                  // Don't subscribe to the message if the connection failed
                  this.signalRService
                    .subscribeMessage('ReceiveMessage')
                    .pipe(takeUntil(this.destroy$))
                    .subscribe({
                      next: async (message) => {
                        const [,messageCode] = message;
                        if (messageCode === 'LogoutSession') {
                          this.wasmService.logout(true);
                        } else if (messageCode === 'ClearSession') {
                          await this.signalRService.unsubscribeServer()
                          // Clear the session and reload the page
                          sessionStorage.removeItem('idsrv-dev');
                          this.window.location.reload();
                        }
                      }
                    });
                });
            }

            const {idp: identityProvider} = this.decodeToken(accessToken);
            const {oid: userId, domainId: domain, grade: userGrade} = userData ?? {};
            const id = userId && !isNaN(Number(userId)) ? parseInt(userId, 10) : 0;
            const grade = userGrade && !isNaN(Number(userGrade)) ? parseInt(userGrade, 10) : Grades.None;

            sessionStorage.setItem('identity_provider_origin', identityProvider?.toLowerCase() ?? 'local');
            this.wasmService.userOid = id; // moving this into the if-block below makes things not load so don't do it
            this.wasmService.accessToken.next(accessToken);
            this.urlService.clearSavedParams();
            
            // Set the SRS API key in sessionStorage for use in the Wasm app
            sessionStorage.setItem('srs', process.env.amiraSRSApiKey ?? '');

            // register unload after we're back from the redirect so we don't 
            // accidently clear the localStorage value
            this.window.addEventListener('beforeunload', event => {
              this.removeStorage();
              
              // Trigger a confirmation dialog if we're in a runtime activity
              const triggerDialog = this.router.url.includes('activity') || this.router.url.includes('welcome');
              if (triggerDialog) {
                event.preventDefault();
              }
            });
            
            if (userData) {
              this.userService.roles = userData.role;
              this.userService.isDebugUser = userData.role.includes("jumpScene");
              this.canUseDebugPanel = this.featureFlags.isFlagEnabled('debugTools') || this.userService.isDebugUser;

              // disable logs if debugTools and jumpScene feature flags are both not found
              if (!this.featureFlags.isFlagEnabled('debugTools') && !this.userService.isDebugUser) {
                this.disableLogs();
              }

              this.ignoreBrowserCheck = this.userService.isDebugUser; // Ignore check if Jump Scene ACL is present
              this.trackingService.push({event:"gtm.user_id", "gtm.user_id": userId});
              this.trackingService.user_id = userId;
              this.userService.givenName.next(userData.given_name);
              this.userService.familyName.next(userData.family_name);
              this.userService.fullName.next(userData.name);
              this.userService.domain.next(userData.domainId);
              this.userService.user.next({
                id,
                grade,
                userId,
                domain,
                isTeacher: false,
                username: '',
                subscription: {
                  subjects: [],
                  isips: [],
                  verbals: []
                },
              });
            }
          }
        },
        error: () => {
          this.router.navigate(['/home']);
          this.location.replaceState('/home');
        }   
      });

    // Subscribe to user changes
    this.userService.theme
      .pipe(
        takeUntil(this.destroy$),
        filter( (themeName: string)=> themeName !== Themes.DEFAULT)
      )
      .subscribe((themeName) => {
        this.loadTheme(themeName);
      });

    this.userService.overlayEnabled
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        (enabled) => (this.overlayEnabled = enabled)
      );

    if (this.loaderService) {
      this.loaderService.loaderState$.pipe(takeUntil(this.destroy$)).subscribe(
        (isLoading) => this.showLoading.next(isLoading)
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  @HostListener('window:keydown.meta.control.w')
  toggleWasm(): void {
    this.wasmService.showWasmBehavior.next(!this.wasmService.showWasmBehavior.value);
  }

  @HostListener('window:keydown.alt.control.m')
  @HostListener('window:keydown.meta.control.m')
  @HostListener('window:' + openDebugConsoleEventName)
  toggleDebug(): void {
    this.wasmService.showDebugPanelBehavior.next(!this.showDebug);
  }

  @HostListener('window:screen.orientation.change')
  checkCompatibility(): void {
    const isAppCompatible = this.compatibilityService.checkBrowser() || this.ignoreBrowserCheck;
    const isScreenCompatible = this.compatibilityService.checkScreenDimensions();
    !isAppCompatible && this.dispatch.error(MessageCodes.APP_COMPAT);
    !isScreenCompatible && this.dispatch.error(MessageCodes.APP_RES);
    this.isCompatible.next(isAppCompatible && isScreenCompatible);
  }

  private loadTheme(themeName: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    const themeUrl = `theme-${themeName}.css`;
    const themeLink = this.document.getElementById(
      this.themeID
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = themeUrl;
    } else {
      const style = this.document.createElement('link');
      style.id = this.themeID;
      style.rel = 'stylesheet';
      style.media = 'screen';
      style.href = themeUrl;
      head.appendChild(style);
    }
  }

  private removeStorage(): void {
    this.localStorage.removeItem(this.tabUniqueName);
  }

  private runOtherTabChecks(): void {
    let tabCheckWait: number;
    const channel = new BroadcastChannel(this.tabUniqueName);
    const checkStorageForOtherTabs = () => this.localStorage.getItem(this.tabUniqueName) ? true : false;
    //start listening for messages so we can respond to other tabs that spawn
    channel.addEventListener('message', ({data: announcement}) => {
      //if we're double checking if it's open we'll send 'anyone-here' from the other tab
      if( announcement === TabState.ANNOUNCE) {
        //respond that we're here and we're alive so they go away. 
        channel.postMessage(TabState.RESPOND);
      } else if (announcement === TabState.RESPOND) {
        //if we're in the newer tab and an older tab is talking, cleanup this tab. 
        this.window.clearTimeout(tabCheckWait);
        this.router.navigate(['error', 'multiple-instances-running-warning']);
      } 
    });

    //Checking local storage for other tabs
    if(checkStorageForOtherTabs()) {
      //if we find another tab, double check it's not crashed.
      channel.postMessage(TabState.ANNOUNCE);
      //wait 1.5 seconds for a response, if we don't get anything we can assume we're free to launch.
      tabCheckWait = this.window.setTimeout(() => this.wasmService.canLaunchWasm.next(true), 1500);
    } else {
      this.wasmService.canLaunchWasm.next(true);
    }
    this.localStorage.setItem(this.tabUniqueName, Date.now().toString());
  }

  private decodeToken(token: string): Partial<AccessTokenPayload> {
    let payload: Partial<AccessTokenPayload> = <AccessTokenPayload>({});
    const [,payloadHex] = token.split('.');
    try {
      if (payloadHex) {
        const payloadBuffer = Buffer.from(payloadHex, 'base64');
        payload = <AccessTokenPayload>(JSON.parse(payloadBuffer.toString('utf-8')));  
      }
    } catch (e) {
      payload;
    }
    return payload;
  }

  // disable all logs by overwriting wondow.console
	// there's not a way back from this...
  private disableLogs(): void {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		const func = () => {};
		Object.assign(window.console, {
			dir: func,
			log: func,
			warn: func,
			debug: func,
			group: func,
			groupCollapsed: func,
			groupEnd: func,
			table: func,
			error: func,
			time: func,
			timeEnd: func,
		});
		console.log('Developer logs disabled. :)');
  }
}
