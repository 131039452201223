import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LOADING } from '../core/injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loadingSubject: BehaviorSubject<boolean> = inject(LOADING);
  private loaderSubject = new BehaviorSubject<boolean>(this.loadingSubject.getValue());
  loaderState$ = this.loaderSubject.asObservable();

  showLoader() {
    this.loaderSubject.next(true);
  }

  hideLoader() {
    this.loaderSubject.next(false);
  }
}