import SubjectDescriptions from "../data/subject-descriptions";
import { SubjectFactory } from "./subject-factory";
import SubjectSubheadings from "../data/subject-subheadings";
import SubjectThumbnails from "../data/subject-thumbnails";
import subjectsInfo from "../data/subjects-info";
import {
    AmiraAssignmentTypes,
    GradeBands,
    LanguageCodes,
} from "@swe/enums";
import { 
    AmiraAssignmentResponse,
    DictionaryISIPValue,
    DictionaryScoresValue,
    InvokeResponseResult,
    IsipMonthInfo,
    IsipSubtest,
    StudentSubject,
    StudentSubjectIsip,
    SubjectEntity
} from "@swe/types";
import { AmiraAssignmentActivityTypes } from "../enums";
import { isAssessmentTypeAssignment, isManifestActivityIncomplete, manifestHasNonEnglishLocale } from "@swe/shared/utilities/amira-assignment-utils";

type StudentSubjects = {
    [key: string]: StudentSubject
};

export type IsipMeta = {
    name: string,
    subheading: string,
    description: string,
    image: string,
    period: number;
    startingPeriod: number;
    lastCompleted?: Date;
    frequency: number;
    subtests:IsipSubtest[],
    totalScores: IsipMonthInfo[],
    goals: IsipMonthInfo[],  
    link: string[],
    resultsLabel: string,
}

export type AssignmentMeta = Pick<IsipMeta, "name" | "subheading" | "description" | "image" | "link"> & {
    assignmentId: string;
    assignmentType: AmiraAssignmentTypes;
    manifestHasNonEnglishLocale?: boolean;
    activityType?: AmiraAssignmentActivityTypes;
    storyId?: string;
    contentTags?: string[];
    numAssignments?: number;
    launchArgs?: {
        activityUuid?: string,
        activityOid?: string,
        skill?: string,
        startingJumpPath?: string,
        endingJumpPath?: string,
        stopBefore?: string,
        storyId?: string
    };
};

export class SubjectCard implements SubjectEntity {
    private _subheading = "";
    private _description = '';
    private _image = '';
    private _link: string[] = [];
    private _assignmentDue = false;
    name = '';
    _name = "";
    isip: IsipMeta = {
        name: "",
        subheading: "Show what you know!",
        description: "",
        image: "assets/thumbnails/Isip_Small.png",
        period: 0,
        frequency: 1,
        startingPeriod: 0,
        subtests: <Array<IsipSubtest>>[],
        totalScores: <Array<IsipMonthInfo>>[],
        goals: <Array<IsipMonthInfo>>[],
        link: <Array<string>>[],
        resultsLabel: "Results",
    }
    _assignment: AssignmentMeta = {
        name: "Assignment Default Name",
        subheading: "Assignment Default Subheading",
        description: "Assignment Default Description",
        image: "assets/thumbnails/StudentInbox_Reading_Assignment.svg", 
        link: [],
        numAssignments: 0,
        assignmentId: '',
        assignmentType: AmiraAssignmentTypes.INSTRUCT, // Safe as default assignmentType
    }

    sortPriority = 0;
    cardValue = 0;
    cardState = 0;
    alt_text = "";
    isVerbal = false;
    language = "";
    limitToGrades: number[] = [];
    isNeeded = false;
    isRunningAdhoc = false;
    isipOnly = false;
    footerLabel = '';
    tier = 0;
  
    constructor({
        name,
        isipDictionary,
        band = GradeBands.BAND_1
    }:{
        name: string,
        isipDictionary?: DictionaryISIPValue,
        band?: GradeBands
    }) {
        const subjects = subjectsInfo as StudentSubjects;
        const validSubject = name in subjects;
        if (validSubject) {
            const subject = structuredClone(subjects[name]);
            subject.image = this.getValue('thumbnail', name, band);
            subject.subheading = this.getValue('subheading', name, band);
            subject.description = this.getValue('description', name, band);
            Object.assign(this, subject);

            // Check for and fill in ISIP
            if (isipDictionary) {
                this.parseIsip(name, isipDictionary, band);
            }
        } else {
            const { Invalid } = subjects;
            Object.assign(this, Invalid);
            throw 'Could not find data for (invalid) subject: ' + name;
        }
    }

    parseIsip(name: string, isipDictionary: DictionaryISIPValue | undefined, band: GradeBands = GradeBands.BAND_1): void {
        const subjects = subjectsInfo as StudentSubjects;
        const isipName = name + 'Isip';
        const validIsip = isipName in subjects;
        const { InvalidIsip } = subjects;
        const isipSubject = (validIsip ? subjects[isipName] : InvalidIsip) as StudentSubjectIsip;
        const isip = <Partial<IsipMeta>>{
            name: isipName,
            image: this.getValue('thumbnail', isipName, band),
            subheading: this.getValue('subheading', isipName, band),
            description: this.getValue('description', isipName, band),
            link: isipSubject.link ?? [],
            resultsLabel: this.getResultsLabel(this.language),
        };

        if (isipDictionary) {
            const [subtests, totalScores, goals] = !!isipDictionary.Scores.value ? this.parseSubtests(isipDictionary?.Scores?.value as DictionaryScoresValue) : [];
            Object.assign(isip, {
                startingPeriod: parseInt(isipDictionary.StartingPeriod.value, 10),
                period: parseInt(isipDictionary.Period.value, 10),
                lastCompleted: SubjectFactory.parseDate(isipDictionary.lastCompleted),
                subtests,
                totalScores,
                goals,
            });
            // Update object properties from dictionary values
            this.isRunningAdhoc = isipDictionary.isRunningAdhoc.value === 'true',
            this.isNeeded = isipDictionary?.IsNeeded?.value === 'true';
        }
        Object.assign(this.isip, isip);
        // console.warn(JSON.parse(JSON.stringify(isip)));
    }

    parseSubtests(scoresList: DictionaryScoresValue): [IsipSubtest[], IsipMonthInfo[], IsipMonthInfo[]] {
       return Object.entries(scoresList)
            .reduce<[IsipSubtest[], IsipMonthInfo[], IsipMonthInfo[]]>(([s, o, g], [key, {value: valuesList}]) => {
                if ( key === 'Skills' ) {
                    s = (Object.values(valuesList) as InvokeResponseResult[]).map<IsipSubtest>(({value: name}) => ({name}));
                } else if ( Object.keys(valuesList).length ) {
                    const valuesArray = Object.entries(valuesList);
                    const scoreArray = valuesArray.map(([scorePeriod, {value: scoreValue}]) => {
                        const period = parseInt(scorePeriod, 10);
                        const value = parseFloat(scoreValue);
                        return Object.assign({}, { period, value });
                    });
                    if (key === 'Overall') {
                        o = scoreArray;
                    } else {
                        g = scoreArray;
                    }
                }
                return [ s, o, g ];
            }, [[], [], []]);
    }

    parseAssignment(name: string, nextAssignment: AmiraAssignmentResponse) {
        if (!nextAssignment) {
            console.warn('Parse Assignment: No next assignment found');
            return;
        }

        if (!nextAssignment.assignmentType || !nextAssignment.assignmentId) {
            console.warn('Parse Assignment: No assignment type or id found for next assignment');
            return;
        }

        if (isAssessmentTypeAssignment(nextAssignment.assignmentType)) {
            // For assessment assignments, the Amira client handles working with the manifest
            // It just needs to know if any manifest object has a non-english locale
            this._assignment.launchArgs = {};
            this._assignment.manifestHasNonEnglishLocale = manifestHasNonEnglishLocale(nextAssignment.manifest);
            this._assignment.assignmentId = nextAssignment.assignmentId;
            this._assignment.assignmentType = nextAssignment.assignmentType;
            this.assignmentDue = true;
        } else {
            // For non-assessment assignments, load next activity data into _assignment
            // Find the first incomplete activity in the manifest
            const nextActivity = nextAssignment.manifest.find(manifestActivity => 
                isManifestActivityIncomplete(manifestActivity)
            );

            if (!nextActivity) {
                console.warn('Parse Assignment: No uncompleted activities found in manifest');
                return;
            }

            if (!nextActivity.launchArgs) {
                console.warn('Parse Assignment: No launchArgs found for next assignment');
                return;
            }

            const activityType = nextActivity.activityType;
            this._assignment.activityType = activityType;
            this._assignment.storyId = nextActivity.storyId;
            this._assignment.contentTags = nextActivity.contentTags;
            this._assignment.assignmentId = nextAssignment.assignmentId;
            this._assignment.assignmentType = nextAssignment.assignmentType;
            this.assignmentDue = true;
            
            switch (activityType as AmiraAssignmentActivityTypes) {
                case AmiraAssignmentActivityTypes.MICROLESSON:
                    this._assignment.launchArgs = {
                        activityUuid: nextActivity.launchArgs.activityUuid,
                        activityOid: nextActivity.launchArgs.activityOid,
                        skill: nextActivity.launchArgs.skill,
                    };
                    //console.log('Parse Assignment: Microlesson', this._assignment.launchArgs);
                    break;
                case AmiraAssignmentActivityTypes.LEGACYISTATION:
                    this._assignment.launchArgs = {
                        startingJumpPath: nextActivity.launchArgs.startingJumpPath,
                        endingJumpPath: nextActivity.launchArgs.endingJumpPath,
                        stopBefore: nextActivity.launchArgs.stopBefore,
                        skill: nextActivity.launchArgs.skill,
                    };
                    //console.log('Parse Assignment: Legacy Istation', this._assignment.launchArgs);
                    break;
                case AmiraAssignmentActivityTypes.STORY:
                    this._assignment.launchArgs = {
                        storyId: nextActivity.launchArgs.storyId,
                    };
                    //console.log('Parse Assignment: Story', this._assignment.launchArgs);
                    break;
                case AmiraAssignmentActivityTypes.SKILL:
                    this._assignment.launchArgs = {
                        skill: nextActivity.launchArgs.skill,
                    };
                    //console.log('Parse Assignment: Skill', this._assignment.launchArgs);
                    break;
                default:
                    // unset assignment due flag since we don't have a valid activity type
                    this.assignmentDue = false;
                    console.warn('Parse Assignment: Unknown activity type', activityType);
                    break;
            }
        }

        this._assignment.link = [nextAssignment.assignmentType];
    }

    isGradeAppropriate(grade: number): boolean {
        return this.limitToGrades && this.limitToGrades.length ? this.limitToGrades.includes(grade) : true;
    }

    private getValue (valueType: 'description' | 'subheading' | 'thumbnail', subjectName: string, gradeBand: GradeBands): string {
        const database: {[index: string]: {[index: number]: string}} = valueType === "description" 
            ? SubjectDescriptions 
                : valueType === "subheading" 
                    ? SubjectSubheadings 
                        : valueType === "thumbnail" 
                            ? SubjectThumbnails 
                                : {};
        // console.warn(subjectName,database[subjectName]);
        return database[subjectName] && database[subjectName][gradeBand] ? database[subjectName][gradeBand] : '';
    }

    private getResultsLabel(language: string) : string {
        switch (language) {
            case LanguageCodes.Spanish: return 'Resultados';
            default: // do nothing
        }
        return 'Results';
    }
    
    /* Native Getters and Setters */
    set description(value: string) {
        this._description = value;
    }
    get description(): string {
        return this.isNeeded ? this.isip.description : (this._assignmentDue ? this._assignment.description ?? this._description : this._description);
    }
    set subheading(value: string) {
        this._subheading = value;
    }
    get subheading(): string {
        return this.isNeeded ? this.isip.subheading : (this._assignmentDue ? this._assignment.subheading ?? this._subheading : this._subheading);
    }
    set image(value: string) {
        this._image = value;
    }
    get image(): string {
        return this.isNeeded ? this.isip.image : (this._assignmentDue ? this._assignment.image : this._image);
    }
    set link(value: string[]) {
        this._link = value;
    }
    get link(): string[] {
        return this.isNeeded ? this.isip.link : (this._assignmentDue ? this._assignment.link : this._link);
    }
    set assignment(subject : StudentSubject) {
        this._assignment.description = subject.description ?? '';
        this._assignment.image = subject.image;
        this._assignment.link = subject.link;
        this._assignment.name = subject.name;
        this._assignment.subheading = subject.subheading ?? '';
    }
    getAssignment(): AssignmentMeta {
        return this._assignment;
    }
    set numAssignments(value: number) {
        this._assignment.numAssignments = value;
    }
    get numAssignments(): number {
        return this._assignment.numAssignments ?? 0;
    }
    set assignmentDue(value: boolean) {
        this._assignmentDue = value;
    }
    get assignmentDue(): boolean {  
        return this._assignmentDue;
    }
}