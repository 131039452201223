import { gql } from 'apollo-angular';

export const GET_USER = gql`
  query ($userId: ID!) {
    student(user_id: $userId) {
      districtId
      schoolId
    }
  }
`;

export default {
  GET_USER,
};
