import { GradeBands } from "../enums"

const SubjectThumbnails = {
    Reading: {
        [GradeBands.BAND_1]: 'assets/thumbnails/ReadingCharacterThumbnail.png',
        [GradeBands.BAND_2]: 'assets/thumbnails/Reading_1-2_Small.png',
        [GradeBands.BAND_3]: 'assets/thumbnails/Reading_3-5_Small.png',
        [GradeBands.BAND_4]: 'assets/thumbnails/Reading_6-8_Small.png',
    },
    Math: {
        [GradeBands.BAND_1]: 'assets/thumbnails/AngelThumbnail.png',
        [GradeBands.BAND_2]: 'assets/thumbnails/Math_1-2_Small.png',
        [GradeBands.BAND_3]: 'assets/thumbnails/Math_3-5_Small.png',
        [GradeBands.BAND_4]: 'assets/thumbnails/Math_6-8_Small.png',
    },
    Spanish: {
        [GradeBands.BAND_1]: 'assets/thumbnails/LeonorThumbnail.png',
        [GradeBands.BAND_2]: 'assets/thumbnails/Lectura_1-2_Small.png',
        [GradeBands.BAND_3]: 'assets/thumbnails/Lectura_3-5_Small.png',
        [GradeBands.BAND_4]: 'assets/thumbnails/Lectura_6-8_Small.png',
    },
    SpanishMath: {
        [GradeBands.BAND_1]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_2]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_3]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_4]: 'assets/thumbnails/Isip_Small.png',
    },
    ReadingIsip: {
        [GradeBands.BAND_1]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_2]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_3]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_4]: 'assets/thumbnails/Isip_Small.png',
    },
    MathIsip: {
        [GradeBands.BAND_1]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_2]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_3]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_4]: 'assets/thumbnails/Isip_Small.png',
    },
    SpanishIsip: {
        [GradeBands.BAND_1]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_2]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_3]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_4]: 'assets/thumbnails/Isip_Small.png',
    },
    SpanishMathIsip: {
        [GradeBands.BAND_1]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_2]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_3]: 'assets/thumbnails/Isip_Small.png',
        [GradeBands.BAND_4]: 'assets/thumbnails/Isip_Small.png',
    },
    Orf: {
        [GradeBands.BAND_1]: 'assets/thumbnails/OrfEn_Small.png',
        [GradeBands.BAND_2]: 'assets/thumbnails/OrfEn_Small.png',
        [GradeBands.BAND_3]: 'assets/thumbnails/OrfEn_Small.png',
        [GradeBands.BAND_4]: 'assets/thumbnails/OrfEn_Small.png',
    },
    EspanolOrf: {
        [GradeBands.BAND_1]: 'assets/thumbnails/OrfSp_Small.png',
        [GradeBands.BAND_2]: 'assets/thumbnails/OrfSp_Small.png',
        [GradeBands.BAND_3]: 'assets/thumbnails/OrfSp_Small.png',
        [GradeBands.BAND_4]: 'assets/thumbnails/OrfSp_Small.png',
    },
    ISIPAROrf: {
        [GradeBands.BAND_1]: 'assets/thumbnails/OrfEn_Small.png',
        [GradeBands.BAND_2]: 'assets/thumbnails/OrfEn_Small.png',
        [GradeBands.BAND_3]: 'assets/thumbnails/OrfEn_Small.png',
        [GradeBands.BAND_4]: 'assets/thumbnails/OrfEn_Small.png',
    },
    ESPAROrf: {
        [GradeBands.BAND_1]: 'assets/thumbnails/OrfSp_Small.png',
        [GradeBands.BAND_2]: 'assets/thumbnails/OrfSp_Small.png',
        [GradeBands.BAND_3]: 'assets/thumbnails/OrfSp_Small.png',
        [GradeBands.BAND_4]: 'assets/thumbnails/OrfSp_Small.png',
    },
    RAN: {
        [GradeBands.BAND_1]: 'assets/thumbnails/RanEn_Small.png',
        [GradeBands.BAND_2]: 'assets/thumbnails/RanEn_Small.png',
        [GradeBands.BAND_3]: 'assets/thumbnails/RanEn_Small.png',
        [GradeBands.BAND_4]: 'assets/thumbnails/RanEn_Small.png',
    },
    EspanolRAN: {
        [GradeBands.BAND_1]: 'assets/thumbnails/RanSp_Small.png',
        [GradeBands.BAND_2]: 'assets/thumbnails/RanSp_Small.png',
        [GradeBands.BAND_3]: 'assets/thumbnails/RanSp_Small.png',
        [GradeBands.BAND_4]: 'assets/thumbnails/RanSp_Small.png',
    },
    AmiraAssessment: {
        [GradeBands.BAND_1]: 'assets/thumbnails/StudentInbox_Reading_Assess.svg',
        [GradeBands.BAND_2]: 'assets/thumbnails/StudentInbox_Reading_Assess.svg',
        [GradeBands.BAND_3]: 'assets/thumbnails/StudentInbox_Reading_Assess.svg',
        [GradeBands.BAND_4]: 'assets/thumbnails/StudentInbox_Reading_Assess.svg',
    },
    ReadingAssignment: {
        [GradeBands.BAND_1]: 'assets/thumbnails/StudentInbox_Reading_Assignment.svg',
        [GradeBands.BAND_2]: 'assets/thumbnails/StudentInbox_Reading_Assignment.svg',
        [GradeBands.BAND_3]: 'assets/thumbnails/StudentInbox_Reading_Assignment.svg',
        [GradeBands.BAND_4]: 'assets/thumbnails/StudentInbox_Reading_Assignment.svg',
    },
    ReadingTutor: {
        [GradeBands.BAND_1]: 'assets/thumbnails/StudentInbox_Reading_Default.svg',
        [GradeBands.BAND_2]: 'assets/thumbnails/StudentInbox_Reading_Default.svg',
        [GradeBands.BAND_3]: 'assets/thumbnails/StudentInbox_Reading_Default.svg',
        [GradeBands.BAND_4]: 'assets/thumbnails/StudentInbox_Reading_Default.svg',
    },
};

export default SubjectThumbnails;