import { gql } from "apollo-angular";

// schema definition: https://github.dev/AmiraLearning/amira-assignment-service/blob/develop/res/schema.graphql

export const STUDENT_INBOX_QUERY = gql`
    query ($studentId: ID!, $schoolId: ID!, $districtId: ID!) {
        studentInbox (studentId: $studentId, schoolId: $schoolId, districtId: $districtId) {
            assignmentId
            assignmentType
            createdAt
            dateFrom
            dateTo
            entityId
            entityType
            grade
            status
            manifest {
                activityType
                contentTags
                locale
                sequence
                status
                storyId
                locale
                launchArgs {
                    storyId
                    endingJumpPath
                    activityOid
                    activityUuid
                    skill
                    startingJumpPath
                    stopBefore
                }
                activity {
                    activityId
                    createdAt
                    displayStatus
                    schoolId
                    updatedAt
                    type
                    studentId
                    storyId
                    status
                    displayStatus
                    score_pass
                    assignmentId
                    parentId
                }
            }
        }
    }
`;

export const ADD_STUDENT_ASSIGNMENT_MUTATION = gql`
    mutation ($studentId: ID!, $parentId: ID!, $schoolId: ID, $districtId: ID) {
        addStudentInboxAssignment (input: {parentId: $parentId, studentId: $studentId, districtId: $districtId, schoolId: $schoolId}) {
            assignmentId
            assignmentType
            createdAt
            dateFrom
            dateTo
            entityId
            entityType
            grade
            status
            manifest {
                activityType
                contentTags
                locale
                sequence
                status
                storyId
                locale
                launchArgs {
                    storyId
                    endingJumpPath
                    activityOid
                    activityUuid
                    skill
                    startingJumpPath
                    stopBefore
                }
                activity {
                    activityId
                    createdAt
                    displayStatus
                    schoolId
                    updatedAt
                    type
                    studentId
                    storyId
                    status
                    displayStatus
                    score_pass
                    assignmentId
                    parentId
                }
            }
        }
    }
`;

export default { 
    STUDENT_INBOX_QUERY,
    ADD_STUDENT_ASSIGNMENT_MUTATION 
};