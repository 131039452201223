import { NgModule } from '@angular/core';
import {
  APOLLO_NAMED_OPTIONS,
  ApolloModule,
  NamedOptions,
} from 'apollo-angular';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { ErrorLink } from '@apollo/client/link/error';
import { RetryLink } from "@apollo/client/link/retry";
import { ConfigurationService, UsageService } from '../../services';
import { GraphQLServices } from '@swe/enums';

@NgModule({
  imports: [HttpClientModule, ApolloModule],
  providers: [
    UsageService,
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(c: ConfigurationService, httpLink: HttpLink): NamedOptions {
        const getErrorLink = (serviceName: GraphQLServices) => new ErrorLink(({ graphQLErrors, networkError }) => {
          const errorMessage = networkError ? networkError.message
            : graphQLErrors ? graphQLErrors.map(({ message }) => message).join(', ')
            : 'Unknown error';
          console.error(`Failed ${serviceName} query:`, errorMessage);
        });
        
        const getRetryLink = (serviceName: GraphQLServices) => {
          const retryStatusCodes = [ 429, 500, 502, 503, 504 ];
          
          return new RetryLink({
            delay: {
              initial: 500,
              max: 5000,
              jitter: true
            },
            attempts: {
              max: 4,
              retryIf: (error, _operation) => {
                if (retryStatusCodes.includes(error.status)) {
                  console.warn(`Error in ${serviceName} query and will retry:`, error.message);
                  return true;
                }
                
                return false;
              }
            }
          });
        };
        
        return {
          usage: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: c.config.usageURL + 'graphql/',
            }),
          },
          assessmentResults: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: c.config.assessmentApiUrl + 'graphql/',
            }),
          },
          contentMetadata: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: c.config.contentMetadataUrl + 'graphql/',
            }),
          },
          amiraAssignment: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: c.config.amiraAssignmentUrl + 'graphql/',
              headers: new HttpHeaders({
                'x-api-key': process.env.amiraAssignmentApiKey ?? '',
              })
            }),
          },
          amiraSRS: {
            cache: new InMemoryCache(),
            link: getErrorLink(GraphQLServices.SRS)
              .concat(getRetryLink(GraphQLServices.SRS))
              .concat(httpLink.create({
                uri: c.config.amiraSRSUrl + 'graphql/',
                headers: new HttpHeaders({
                  'x-api-key': process.env.amiraSRSApiKey ?? '',
                })
              })),
          },
          amiraSIS: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: c.config.amiraSISUrl + 'graphql/',
              headers: new HttpHeaders({
                'x-api-key': process.env.amiraSISApiKey ?? '',
              })
            }),
          },
        };
      },
      deps: [ConfigurationService, HttpLink],
    },
  ],
})
export class GraphqlModule {}
