export enum AmiraAssignmentActivityTypes {
    MICROLESSON = 'microLesson',
    LEGACYISTATION = 'istationLegacyActivity',
    STORY = 'tutor',
    SKILL = 'amiraSkill',
    UNKNOWN = "unknown",
}

export enum AmiraAssignmentStatus {
    ASSIGNED = 'assigned',
    SCORING_NOT_STARTED = 'not_started',
    SCORING_IN_PROGRESS = 'in_progress',
    SCORED = 'scored',
    RESCORED = 'rescored',
    ABORTED = 'aborted',
    COMPLETED = 'completed',
    UNDER_REVIEW = 'underReview',
    ARCHIVED = 'archived',
}

export enum AmiraAssignmentDisplayStatus {
    RESCORED = 'rescored',
    SCORED = 'scored',
    ASSIGNED = 'assigned',
    ASSIGNED_WITH_SPACES = ' assigned ',
    NOT_STARTED = 'not_started',
    IN_PROGRESS = 'in_progress',
    SCORING = 'scoring',
    LAUNCHED = 'launched',
    STARTED = 'started',
    ABORTED = 'aborted',
    ABANDONED = 'abandoned',
    DOWNLEVELED = "downleveled",
    PREREADER = "prereader",
    COMPLETED = "completed",
    UNASSIGNED = 'unassigned',
    RESCORING = 'rescoring',
    MUTED = 'muted',
    STUCK_PLACEMENT = 'stuck_placement',
    UNDER_REVIEW = 'underReview',
    ARCHIVED = 'archived',
    UPLEVELED = 'upleveled'
}

export enum AmiraAssignmentEntityTypes {
    DISTRICT = 'DISTRICT',
    SCHOOL = 'SCHOOL',
    GROUP = 'GROUP',
    TEACHER = 'TEACHER',
    STUDENT = 'STUDENT',
}

export enum AmiraAssignmentTypes {
    ASSESSMENT = 'ASSESSMENT',
    CALIBRATION = 'CALIBRATION',
    BENCHMARK = 'BENCHMARK',
    PROGRESS_MONITORING = 'PROGRESS_MONITORING',
    INSTRUCT = 'INSTRUCT',
    CURRICULUM = 'CURRICULUM',
}