import { GradeBands } from "../enums"

const SubjectDescriptions = {
    Reading: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: 'Adventures in reading',
    },
    Math: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: 'Defeat villains with your math skills.',
    },
    Spanish: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: 'Aventuras en la lectura.',
    },
    ReadingIsip: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: '',
    },
    MathIsip: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: '',
    },
    SpanishIsip: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: '',
    },
    Orf: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: '',
    },
    EspanolOrf: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: '',
    },
    ISIPAROrf: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: '',
    },
    ESPAROrf: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: '',
    },
    RAN: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: '',
    },
    EspanolRAN: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: '',
    },
    AmiraAssessment: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: 'Show what you know',
    },
    ReadingAssignment: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: 'Learn with Amira',
    },
    ReadingTutor: {
        [GradeBands.BAND_1]: '',
        [GradeBands.BAND_2]: '',
        [GradeBands.BAND_3]: '',
        [GradeBands.BAND_4]: 'Read a story with Amira',
    },
};

export default SubjectDescriptions;