import { StudentSubject, StudentSubjectIsip, StudentSubjectAmiraAssignment } from '../types';
import { IsipShortcutParams, LanguageCodes, SubjectCardState } from '../enums';
import activityActions from './activity-actions';

const subjectsInfo: {
  [index: string]: StudentSubject | StudentSubjectIsip | StudentSubjectAmiraAssignment
} = {
  Invalid: {
    _name: 'Invalid',
    name: 'Invalid Subject',
    sortPriority: 0,
    cardValue: 0,
    cardState: SubjectCardState.unavailable,
    image: 'assets/thumbnails/default.png',
    alt_text: 'Invalid Subject thumbnail',
    subheading: 'Invalid subject subheading',
    description: 'This is an invalid subject and should not be seen',
    link: [],
    usage: 0,
    isVerbal: false,
    language: LanguageCodes.English,
    isNeeded: false,
  },
  InvalidIsip: {
    _name: 'InvalidIsip',
    name: 'Invalid ISIP',
    sortPriority: 0,
    cardValue: 0,
    cardState: SubjectCardState.unavailable,
    image: 'assets/thumbnails/default.png',
    alt_text: 'Invalid ISIP thumbnail',
    subheading: 'Invalid ISIP',
    description: 'This is an invalid ISIP and should not be seen',
    link: [],
    isNeeded: false,
    startingPeriod: 7,
    isRunningAdhoc: false,
    frequency: 1,
    subtests: [],
    totalScores: [],
    tier: 1,
    goals: [],
    isVerbal: false,
    language: LanguageCodes.English,
  },
  Reading: {
    _name: 'Reading',
    name: 'Reading',
    sortPriority: 10,
    cardValue: 0,
    cardState: SubjectCardState.learningPath,
    image: 'assets/thumbnails/Reading_1-2_Large.png',
    alt_text: 'Reading thumbnail',
    subheading: 'Activity Highlight',
    description:
      "If you don't know what reading is, you probably can't read this, but this is what you need to click on to learn it!",
    link: activityActions.ReadingLinear,
    footerLabel: 'Menu',
    usage: 15,
    isVerbal: false,
    language: LanguageCodes.English,
    isNeeded: false,
  },
  Spanish: {
    _name: 'Spanish',
    name: 'Lectura',
    sortPriority: 30,
    cardValue: 1,
    cardState: SubjectCardState.learningPath,
    image: 'assets/thumbnails/Lectura_1-2_Large.png',
    alt_text: 'Lectura thumbnail',
    subheading: 'Activity Highlight',
    description:
      "My Spanish really isn't that great. I don't think I remember much from middle schoool, which is the last time I was in a class for it",
    link: activityActions.LecturaLinear,
    footerLabel: 'Menú',
    usage: 21,
    isVerbal: false,
    language: LanguageCodes.Spanish,
    isNeeded: false,
  },
  Math: {
    _name: 'Math',
    name: 'Math',
    sortPriority: 20,
    cardValue: 2,
    cardState: SubjectCardState.learningPath,
    image: 'assets/thumbnails/Math_1-2_Large.png',
    alt_text: 'Math thumbnail',
    subheading: 'Activity Highlight',
    description:
      "Did you ever want to know that 1+1=2 and other such thrilling information? Well, you're in luck, because that's what this is!",
    link: activityActions.MathLinear,
    footerLabel: 'Menu',
    usage: 10,
    isVerbal: false,
    language: LanguageCodes.English,
    isNeeded: false,
  },
  SpanishMath: {
    _name: 'SpanishMath',
    name: 'Matemáticas',
    sortPriority: 40,
    cardValue: 2,
    cardState: SubjectCardState.learningPath,
    image: 'assets/thumbnails/Math_1-2_Large.png',
    alt_text: 'Matemáticas thumbnail',
    subheading: 'Activity Highlight',
    description:
      "[PH] Spanish Math",
    link: [],
    footerLabel: '',
    usage: 10,
    isVerbal: false,
    language: LanguageCodes.Spanish,
    isNeeded: false,
    limitToGrades: [-1, 0, 1, 2, 3, 4, 5],
  },
  ESPAROrf: {
    _name: 'ESPAROrf',
    name: 'ORF en Español',
    sortPriority: 121,
    cardValue: 0,
    cardState: SubjectCardState.isip,
    image: 'assets/thumbnails/OrfSp_Large.png',
    alt_text: 'ESPAROrf thumbnail',
    subheading: 'ESPAROrf Subheading',
    description:
      "[PH] ESPAR Orf description text",
    link: ['verbals', IsipShortcutParams.SpanishOrf],
    usage: 10,
    isVerbal: true,
    language: LanguageCodes.Spanish,
    isNeeded: false,
  },
  EspanolOrf: {
    _name: 'EspanolOrf',
    name: 'ORF en Español',
    sortPriority: 120,
    cardValue: 1,
    cardState: SubjectCardState.isip,
    image: 'assets/thumbnails/OrfSp_Large.png',
    alt_text: 'EspanolOrf thumbnail',
    subheading: 'EspanolOrf Subheading',
    description:
      "[PH] Espanol Orf description text",
    link: ['verbals', IsipShortcutParams.SpanishOrf],
    usage: 10,
    isVerbal: true,
    language: LanguageCodes.Spanish,
    isNeeded: false,
  },
  EspanolRAN: {
    _name: 'EspanolRAN',
    name: 'RAN en Español',
    sortPriority: 122,
    cardValue: 1,
    cardState: SubjectCardState.isip,
    image: 'assets/thumbnails/RanSp_Large.png',
    alt_text: 'EspanolRAN thumbnail',
    subheading: 'EspanolRAN Subheading',
    description:
      "[PH] Espanol RAN description text",
    link: ['verbals', IsipShortcutParams.SpanishRan],
    usage: 10,
    isVerbal: true,
    language: LanguageCodes.Spanish,
    isNeeded: false,
  },
  ISIPAROrf: {
    _name: 'ISIPAROrf',
    name: 'ORF',
    sortPriority: 111,
    cardValue: 1,
    cardState: SubjectCardState.isip,
    image: 'assets/thumbnails/OrfEn_Large.png',
    alt_text: 'ISIPAROrf thumbnail',
    subheading: 'ISIPAROrf Subheading',
    description:
      "[PH] ISIPAR Orf description text",
    link: ['verbals', IsipShortcutParams.EnglishOrf],
    usage: 10,
    isVerbal: true,
    language: LanguageCodes.English,
    isNeeded: false,
  },
  Orf: {
    _name: 'Orf',
    name: 'ORF',
    sortPriority: 110,
    cardValue: 1,
    cardState: SubjectCardState.isip,
    image: 'assets/thumbnails/OrfEn_Large.png',
    alt_text: 'Orf thumbnail',
    subheading: 'Orf Subheading',
    description:
      "[PH] Orf description text",
    link: ['verbals', IsipShortcutParams.EnglishOrf],
    usage: 10,
    isVerbal: true,
    language: LanguageCodes.English,
    isNeeded: false,
  },
  RAN: {
    _name: 'RAN',
    name: 'RAN',
    sortPriority: 112,
    cardValue: 1,
    cardState: SubjectCardState.isip,
    image: 'assets/thumbnails/RanEn_Large.png',
    alt_text: 'RAN thumbnail',
    subheading: 'RAN Subheading',
    description:
      "[PH] RAN description text",
    link: ['verbals', IsipShortcutParams.EnglishRan],
    usage: 10,
    isVerbal: true,
    language: LanguageCodes.English,
    isNeeded: false,
  },
  ReadingIsip: {
    _name: 'ReadingIsip',
    name: 'Reading',
    sortPriority: 10,
    cardValue: 0,
    cardState: SubjectCardState.isip,
    image: 'assets/thumbnails/ReadingISIP_0.png',
    alt_text: 'Reading ISIP thumbnail',
    subheading: 'Reading ISIP',
    description: 'ISIP is ready',
    link: ['menu', 'reading/isip', 'start'],
    footerLabel: 'Menu',
    isNeeded: false,
    startingPeriod: 7,
    isRunningAdhoc: false,
    frequency: 1,
    subtests: [],
    totalScores: [],
    tier: 1,
    goals: [],
    isVerbal: false,
    language: LanguageCodes.English,
    modelingForGrades: [-1, 0, 1, 2, 3],
  },
  SpanishIsip: {
    _name: 'SpanishIsip',
    name: 'Lectura',
    sortPriority: 30,
    cardValue: 0,
    cardState: SubjectCardState.isip,
    image: 'assets/thumbnails/LecturaISIP_0.png',
    alt_text: 'Lectura ISIP thumbnail',
    subheading: 'Lectura ISIP',
    description: 'ISIP is ready',
    link: ['menu', 'lectura/isip', 'start'],
    footerLabel: 'Menú',
    isNeeded: false,
    startingPeriod: 7,
    isRunningAdhoc: false,
    frequency: 1,
    subtests: [],
    totalScores: [],
    tier: 1,
    goals: [],
    isVerbal: false,
    language: LanguageCodes.Spanish,
    modelingForGrades: [-1, 0, 1, 2, 3],
  },
  MathIsip: {
    _name: 'MathIsip',
    name: 'Math',
    sortPriority: 20,
    cardValue: 0,
    cardState: SubjectCardState.isip,
    image: 'assets/thumbnails/MathISIP_0.png',
    alt_text: 'Math ISIP thumbnail',
    subheading: 'Math ISIP',
    description: 'ISIP is ready',
    link: ['menu', 'math/isip', 'start'],
    footerLabel: 'Menu',
    isNeeded: false,
    startingPeriod: 7,
    isRunningAdhoc: false,
    frequency: 1,
    subtests: [],
    totalScores: [],
    tier: 1,
    goals: [],
    isVerbal: false,
    language: LanguageCodes.English,
    modelingForGrades: [-1, 0, 1],
  },
  SpanishMathIsip: {
    _name: 'SpanishMathIsip',
    name: 'Matemáticas',
    sortPriority: 40,
    cardValue: 0,
    cardState: SubjectCardState.isip,
    image: 'assets/thumbnails/SpanishMathISIP_0.png',
    alt_text: 'Spanish Math ISIP thumbnail',
    subheading: 'Matemáticas ISIP',
    description: 'ISIP is ready',
    link: ['isip-skip-flow', 'Matemáticas'],
    isNeeded: false,
    startingPeriod: 7,
    isRunningAdhoc: false,
    frequency: 1,
    subtests: [],
    totalScores: [],
    tier: 1,
    goals: [],
    isVerbal: false,
    language: LanguageCodes.Spanish,
    modelingForGrades: [],
    limitToGrades: [-1, 0, 1, 2, 3, 4, 5],
  },
  AmiraAssessment: {
    _name: 'AmiraAssessment',
    name: 'Reading',
    sortPriority: 10,
    cardValue: 0,
    cardState: SubjectCardState.isip,
    image: 'assets/thumbnails/isip_small.png',
    alt_text: 'Assessment thumbnail',
    subheading: 'Assessment Time!',
    description: 'Show what you know',
    link: activityActions.ReadingAssignments, // TODO: make this unique
    footerLabel: 'Menu',
    usage: 15,
    isVerbal: false,
    language: LanguageCodes.English,
    isNeeded: false,
  },
  ReadingAssignment: {
    _name: 'ReadingAssignment',
    name: 'Reading',
    sortPriority: 10,
    cardValue: 0,
    cardState: SubjectCardState.assignment,
    image: 'assets/nav/folder-front.svg',
    alt_text: 'Assignment thumbnail',
    subheading: 'New Assignment!',
    description: 'Learn with Amira',
    link: activityActions.ReadingAssignments, // TODO: make this unique
    footerLabel: 'Menu',
    usage: 15,
    isVerbal: false,
    language: LanguageCodes.English,
    isNeeded: false,
  },
  ReadingTutor: {
    _name: 'ReadingTutor',
    name: 'Reading',
    sortPriority: 10,
    cardValue: 0,
    cardState: SubjectCardState.tutor,
    image: 'assets/thumbnails/explore-reading_2.svg',
    alt_text: 'Tutor thumbnail',
    subheading: 'Read with Amira!',
    description: 'Read a story with Amira',
    link: activityActions.ReadingTutor,
    footerLabel: 'Menu',
    usage: 15,
    isVerbal: false,
    language: LanguageCodes.English,
    isNeeded: false,
  },
};

export default subjectsInfo;
