import { Injectable } from "@angular/core";
import Queries from "@swe/data/queries/amira-sis-queries";
import { AmiraSISGetUserInput, AmiraSISGetUserResponse } from "@swe/types/amira-sis-types";
import { Apollo, ApolloBase } from "apollo-angular";

@Injectable({
    providedIn: 'root',
})
export class AmiraSISService {
    public apollo: ApolloBase;
    
    constructor(private apolloProvider: Apollo) {
        this.apollo = apolloProvider.use('amiraSIS');
    }
    
    async getUserMetaIDs(input: AmiraSISGetUserInput): Promise<AmiraSISGetUserResponse> {
        return this.apollo.query<{ student: AmiraSISGetUserResponse }>({
            query: Queries.GET_USER,
            variables: input,
            errorPolicy: 'all'
        }).toPromise().then((result) => {
            if (result.errors) {
                console.error('Error getting user meta IDs:', result.errors.map(e => e.message));
                throw new Error('User meta IDs query failed.');
            }

            if (
                !result.data?.student?.districtId ||
                result.data?.student?.districtId === "" ||
                !result.data?.student?.schoolId ||
                result.data?.student?.schoolId === ""
            ) {
                throw new Error('User meta IDs not found.');
            }

            return {
                schoolId: result.data.student.schoolId.toString(),
                districtId: result.data.student.districtId.toString()
            };
        }).catch((error) => {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            console.error('Failed user meta IDs query:', errorMessage);
            throw new Error(`Failed user meta IDs query > ${errorMessage}`);
        });
    }
}